import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const AboutContent = ( {data} ) => {
  const intl = useIntl()

  return (
    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {intl.formatMessage({ id: "aboutTitle" })}
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              {intl.formatMessage({ id: "aboutSubTitle" })}
            </h2>
            <div className="mt-4 leading-loose">
              {intl.formatMessage({ id: "content_first" })}
              <br />
              <br />
              {intl.formatMessage({ id: "content_second" })}
              <br />
              <br />
              {intl.formatMessage({ id: "content_third" })}
              <br />
              mandw.entertainment＠gmail.com
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.author.childImageSharp.fluid}
              alt="Diving"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  )
}


export default AboutContent
