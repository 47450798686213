import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import AboutContent from "../components/AboutContent"
import SiteMetadata from "../components/SiteMetadata"
import { useIntl } from "gatsby-plugin-intl"

const AboutPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      {intl.locale === "en" && (
        <SiteMetadata title="About | MandW Entertainment"
                      description="Developers who work seriously while having fun"
                      image={data.author.publicURL}
                      slug={"about"} />
      )}
      {intl.locale === "ja" && (
        <SiteMetadata title="About | MandW Entertainment"
                      description="楽をしながら真面目に開発するデベロッパー"
                      image={data.author.publicURL}
                      slug={"about"} />
      )}

      <div>
        {}
      </div>
      <AboutContent data={data}/>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
  }
`
